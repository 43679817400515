import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import * as firebase from 'firebase';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  siginerror:any;
  authemail:any;
  authpassword:any;
  errorofmessage:any;
  admiin:any;
  _session:any;
  loginshow:any;
  constructor(private authService: AuthService,private router: Router) { }

  ngOnInit() {
    this.authpassword = false;
    this.authemail = false;
    this.errorofmessage = false;
    this.admiin = false;
    
  }

  onSignin(form: NgForm) {
    if(form.value.email == 'chat@psl.com'){
      if(form.value.email  ){
        if(form.value.password){
          const email = form.value.email;
          const password = form.value.password;
          this.loginshow = true;
          firebase.auth().signInWithEmailAndPassword(email, password).then(
            response => {
              this.router.navigate(['/dashboard']);
            this._session = response.user.uid
            localStorage.setItem('localst', 'true');
          }).catch(
            error => {
              console.log(error.message)
              this.siginerror = error.message
              this.errorofmessage = true;
              this.authpassword = false;
              this.authemail = false;
              this.loginshow = false;
  
              this.timeOuts()
    
            }
          );;
        }else{
          this.authpassword = true;
          this.errorofmessage = false;
          this.authemail = false;
          this.timeOuts()
        }
      }else{
        this.authemail = true;
        this.errorofmessage = false;
        this.authpassword = false;
        this.timeOuts()
  
      }
    }else{
      this.admiin = true;
      this.timeOuts()
    }
    
    
  }
  timeOuts(){
    setTimeout(() => {
      this.errorofmessage = false;
      this.authpassword = false;
      this.authemail = false;
      this.admiin = false;
    }, 4000);
  }
}
