import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
// import { do } from 'rxjs/add/operator/do';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/take';

// import {Observable} from 'rxjs'; 

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      
      if (this.auth.authenticated) { 
        console.log(this.auth.authenticated)
        return true;
      }
      else{
        this.router.navigate(['/login'])
        return false;
      }

      // return this.auth.currentUserObservable
      //   .take(1)
      //   .map(user => !!user)
      //   .do(loggedIn => {
      //     if (!loggedIn) {
      //       console.log("access denied")
      //       this.router.navigate(['/login']);
      //   }
      // })

  }
}