import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { AuthService } from '../auth.service'; 
@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css']
})
export class BlockComponent implements OnInit {
  show:boolean = false;
  toster:boolean;
  userStatus:boolean;
  channel:any = '';
  response : any;
  dbChannels : any;
  tosterEmpty : any;
  loadingspin:any;
  sidebarshow:boolean = true;
  sidebarshowmobile:boolean = true;
  pipe = new DatePipe('en-US');
  dbUsers:any = '';
  constructor(private authService: AuthService,private http:HttpClient) {
    
   }

  ngOnInit() {
    this.toster =  false;
    let obt = this.http.get('https://cricket-fans-chat.firebaseio.com/Production.json');
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbChannels = this.response.ReportedMessages  ;
    });
    // myShortFormat = this.pipe.transform(this.now, 'short');

  }
  receiveMessage($event) {
    this.sidebarshow = $event;
    this.sidebarshowmobile = $event;
  }
  isBlock(key){
    console.log(key)
    let obt = this.http.get('https://cricket-fans-chat.firebaseio.com/Production/Users/' + key + '.json');
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbUsers = this.response  ;
      this.userStatus = this.dbUsers.isBlocked
      if(this.userStatus == undefined){
        this.userStatus = false
      }

    });
  }
  isBlockChangeStatus(key){
    firebase.database().ref('Production/Users/' + key + "/isBlocked").set( Boolean(this.userStatus) ).then((data) => {
      firebase.database().ref('Production/Users/' + key + "/blockedTime").set(Date.now()).then((data) => {

      });
    });
    
  }

  

  
}
