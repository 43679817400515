import { Component, OnDestroy } from '@angular/core';
import 'core-js/es7/array';
import * as firebase from 'firebase';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

export let browserRefresh = false;
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy{
  title = 'ucware';
  name = 'Angular 6';
  subscription: Subscription;
  loadingspin:any;
  lang: string
  constructor(private router: Router) {

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });

  }

  
    ngOnDestroy() {
      this.subscription.unsubscribe();
    }

  ngOnInit() {
  
    firebase.initializeApp({
      // credential: admin.credential.applicationDefault(),
      apiKey: "AIzaSyD8A50F1oaJ8XWUPlxwGAyyqsxgowh1pJY",
      authDomain: "cricket-fans-chat.firebaseapp.com",
      databaseURL: "https://cricket-fans-chat.firebaseio.com"
    });
    this.loadingspin = true;
    setTimeout(() => {
      this.loadingspin = false;
  }, 3000);
  }

}
