import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  channel:any = '';
  response : any;
  dbChannels : any;
  sidebarshow:boolean = true;
  sidebarshowmobile:boolean = true;
  parentMessage = "message from parent"

  message:string;

  receiveMessage($event) {
    this.sidebarshow = $event;
    this.sidebarshowmobile = $event;
    console.log("here", this.sidebarshow)
  }
  receiveMessageMobile($event) {
    this.sidebarshowmobile = $event;
    console.log("here", this.sidebarshowmobile)
  }
  constructor(private authService: AuthService,private http:HttpClient) { }

  ngOnInit() {
    let obt = this.http.get('https://cricket-fans-chat.firebaseio.com/Production/Users.json');
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbChannels = Object.keys(this.response).length ;
    });

    this.sidebarshow = localStorage.sidebar; 
  }

}
