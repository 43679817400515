import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelect2Module } from 'ng-select2';
import { AuthService } from './auth.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChatComponent } from './uc-slack/chat/chat.component';
import { LoginComponent } from './login/login.component';
import { ChannelsComponent } from './channels/channels.component';
import { AuthGuard } from './auth.guard';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { FileDropModule } from 'ngx-file-drop';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { GoogleChartsModule } from 'angular-google-charts';
import { APP_INITIALIZER } from '@angular/core';
import { BlockComponent } from './block/block.component';

@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent,
    TopBarComponent,
    DashboardComponent,
    ChatComponent,
    LoginComponent,
    ChannelsComponent,
    BlockComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelect2Module,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    FileDropModule,
    LoadingBarRouterModule,
    GoogleChartsModule.forRoot(),
    
  ],
  providers: [
    AuthService,AuthGuard,
        ],
  bootstrap: [AppComponent]
})
export class AppModule { }
