import { Component, OnInit,AfterViewChecked, ElementRef, ViewChild, } from '@angular/core';
import { AuthService } from '../../auth.service';
import { NgForm } from '@angular/forms';
import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements  OnInit, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  sidebarshow:boolean = true;
  sidebarshowmobile:boolean = true;
  response:any = '';
  responses:any = '';
  dbMatches:any = '';
  dbChatM:any = '';
  dbTeams:any = '';
  dbChatT:any = '';
  chatTitle:any = '';
  Cmessage:any = '';
  SenderId:any = '';
  teams: boolean = false;
  utc_timestamp:any = '';
  team:any = '';
  teamOne:any = '';
  teamTwo:any = '';
  teamOness:any = '';
  teamTwoss:any = '';
  sendOption:any = '';
  matchdate:any = '';
  pipe = new DatePipe('en-US');
  date:any = '';


  constructor(private authService: AuthService,private http:HttpClient) { }

  ngOnInit() {
    let obt = this.http.get('https://cricket-fans-chat.firebaseio.com/Production.json');
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbMatches = this.response.Channels.Matches  ;
      // for (let key in this.dbMatches) {
      //   this.matchdate = this.dbMatches[key].date
      //   console.log(this.matchdate);
      // };
      this.date =new Date;
      this.matchdate = this.pipe.transform(this.date, 'dd-MM-yyyy')
      console.log(this.matchdate )
    });
    let obts = this.http.get('https://cricket-fans-chat.firebaseio.com/Production.json');
    obts.subscribe((responses) =>{
      this.responses = responses ;
      this.dbTeams = this.response.Channels.Chat.Teams  ;
    });
    this.chatTitle = "Select Your Chat"
    this.sendOption = ''; 

    this.scrollToBottom();
  }
        ngAfterViewChecked() {        
              this.scrollToBottom();        
          } 

          scrollToBottom(): void {
              try {
                  this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
              } catch(err) { }                 
          }

  receiveMessage($event) {
    this.sidebarshow = $event;
    this.sidebarshowmobile = $event;
  }
  receiveMessageMobile($event) {
    this.sidebarshowmobile = $event;
  }


  // Chat Component
  Cmatches(key, t1, t2){
    this.sendOption = 'aaaa'; 
    console.log(key, t1, t2)
    this.teams = false;
    this.dbChatM = {};
    this.dbChatT = {};
    this.chatTitle = "--------";
    this.SenderId = '';
    this.SenderId = key;
    console.log(key)
    let obt = this.http.get("https://cricket-fans-chat.firebaseio.com/Production/Channels/Chat/Matches/" + key + ".json");
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbChatM = this.response  ;
      this.teamOness = t1;
      this.teamTwoss = t2;
      if(this.teamOness === 1){
        this.teamOne = 'Islamabad United'; 
      } else if(this.teamOness === 2){
        this.teamOne = 'Lahore Qalandars';
      }else if(this.teamOness === 3){
        this.teamOne = 'Peshawar Zalmi';
      }else if(this.teamOness === 4){
        this.teamOne = 'Quetta Gladiators';
      }else if(this.teamOness === 5){
        this.teamOne = 'Multan Sultans';
      }else{
        this.teamOne = 'Karachi Kings';
      }

      if(this.teamTwoss === 1){
        this.teamTwo = 'Islamabad United'; 
      } else if(this.teamTwoss === 2){
        this.teamTwo = 'Lahore Qalandars';
      }else if(this.teamTwoss === 3){
        this.teamTwo = 'Peshawar Zalmi';
      }else if(this.teamTwoss === 4){
        this.teamTwo = 'Quetta Gladiators';
      }else if(this.teamTwoss === 5){
        this.teamTwo = 'Multan Sultans';
      }else{
        this.teamTwo = 'Karachi Kings';
      }
      this.chatTitle = this.teamOne + " Vs " + this.teamTwo ;
    });
  }
  Cteams(key,t){
    this.sendOption = 'aaaa'; 
    this.teams = true;
    this.dbChatM = {}
    this.dbChatT = {}
    this.chatTitle = "--------";
    this.SenderId = '';
    this.SenderId = key;
    console.log(key)
    let obt = this.http.get("https://cricket-fans-chat.firebaseio.com/Production/Channels/Chat/Teams/" + key + ".json");
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbChatT = this.response  ;
      this.chatTitle = t;
    });
  }
  SendCmatches(key){
    var now = new Date;
     this.utc_timestamp = Date.now();
    if(this.teams){
      if(this.Cmessage ){
        firebase.database().ref('Production/Channels/Chat/Teams/' + key + '/' ).push(
          {
            text:this.Cmessage,
            id:String(key),
            sentTime:this.utc_timestamp,
            userDisplayName:"ADMIN OF PSL FANS",
            userId:"a9Jt2pbbnXggyy8SeCv98nBSqop2",
            userProfilePicture:"https://pngimage.net/wp-content/uploads/2018/05/admin-png-4.png",
            userFavouriteTeamId: Number("4"),
            type:"text",
          }).then((data) => { 
            // this.Cteams(key)
            console.log(data.key)
            firebase.database().ref('Production/Channels/Chat/Teams/' + key + '/' + data.key + "/" ).update({
              id:data.key,
            })
            this.Cmessage = ""; 
            this.team = '';
            if(key === 1){
              this.team = 'Islamabad United'; 
            } else if(key === 2){
              this.team = 'Lahore Qalandars';
            }else if(key === 3){
              this.team = 'Peshawar Zalmi';
            }else if(key === 4){
              this.team = 'Quetta Gladiators';
            }else if(key === 5){
              this.team = 'Multan Sultans';
            }else{
              this.team = 'Karachi Kings';
            }
            this.Cteams(key,this.team) 
          }); 
      }else{
        console.log('False')
      }
    }else{
      if(this.Cmessage ){
        firebase.database().ref('Production/Channels/Chat/Matches/' + key + '/' ).push(
          {
            text:this.Cmessage,
            id:String(key),
            sentTime:this.utc_timestamp,
            userDisplayName:"ADMIN OF PSL FANS",
            userId:"a9Jt2pbbnXggyy8SeCv98nBSqop2",
            userProfilePicture:"https://pngimage.net/wp-content/uploads/2018/05/admin-png-4.png",
            userFavouriteTeamId: Number("4"),
            type:"text",
          }).then((data) => { 
            console.log(data.key)
            firebase.database().ref('Production/Channels/Chat/Matches/' + key + '/' + data.key + "/" ).update({
              id: data.key,
            })
            this.Cmessage = "";  
            this.Cmatches(key,this.teamOness,this.teamTwoss);
          }); 
      }else{
        console.log('False')
      }
    }
    
  }
}
