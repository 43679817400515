import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { AuthService } from '../auth.service';
@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.css']
})
export class ChannelsComponent implements OnInit {
  show:boolean = false;
  toster:boolean;
  channel:any = '';
  teamone:any = '0';
  teamtwo:any = '0';
  date:any = '';
  time:any = '';
  response : any;
  dbChannels : any;
  tosterEmpty : any;
  channeldelete :any;
  loadingspin:any;
  sidebarshow:boolean = true;
  sidebarshowmobile:boolean = true;
  pipe = new DatePipe('en-US');
  venu: any ='';
  status: any ='To Be Played';
  statuss: any ='';
  constructor(private authService: AuthService,private http:HttpClient) {
    
   }

  ngOnInit() {
    this.toster =  false;
    let obt = this.http.get('https://cricket-fans-chat.firebaseio.com/Production.json');
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbChannels = this.response.Channels.Matches  ;
    });
    // myShortFormat = this.pipe.transform(this.now, 'short');

  }
  receiveMessage($event) {
    this.sidebarshow = $event;
    this.sidebarshowmobile = $event;
  }
  
  refreshC(){
    let obt = this.http.get('https://cricket-fans-chat.firebaseio.com/Production.json');
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbChannels = this.response.Channels.Matches  ;
      
      
    });
  }
  onChannel(form: NgForm) {
    
    if(this.teamone != 0 && this.teamtwo != 0 ){
      if( this.date && this.time){
      
        this.tosterEmpty = false;
        this.toster = true;
        this.show = true;
      firebase.database().ref('Production/Channels/Matches' ).push(
      {
        teamOneId:Number(this.teamone),
        teamTwoId:Number(this.teamtwo),
        time:this.time,
        date:this.pipe.transform(this.date, 'dd-MM-yyyy'),
        venue:this.venu,
        status:this.status
      }).then((data) => { 
        this.refreshC()
        this.toster = false;
        this.teamone = "0";
        this.teamtwo = "0";
        this.time = "";
        this.date = "";
        this.venu = "";
        
        this.timeout();
  
      }); 
    }else{
      this.tosterEmpty = true;
      this.timeout()
    }
    }else{
    this.tosterEmpty = true;
    this.timeout()
  }
  }
  channelDelete(key){
    if(confirm("Deleting channel will remove whole chat history?")) {
      firebase.database().ref('Production/Channels/Matches/' + key).remove()
      this.refreshC()
      this.channeldelete = true;
      this.timeout();
    }
  }
  editsubmit(key, object){
    firebase.database().ref('Production/Channels/Matches/' + key + "/status").set(object.status).then((data) => { 
        this.refreshC()
        this.timeout();
  
      }); 
  }
  timeout() {
    setTimeout(() => {
        this.show = false;
        this.channeldelete = false;
        this.tosterEmpty = false;
    }, 1000);
}

  
}
