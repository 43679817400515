import { Router } from '@angular/router';
// import * as firebase from 'firebase';
import { Injectable } from '@angular/core';
// import * as admin from 'firebase-admin'
import * as firebase from 'firebase';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;
  show:any;
  authState:any;
  _session:any;
  siginerror:any;
  constructor(private router: Router,private http: HttpClient) {
    const endpoint = 'https://cricket-fans-chat.firebaseio.com/.json';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
   }
   ngOnInit(){
      this.getToken
   }




  private extractData(res: Response) {
  let body = res;
  return body || { };
  }
  get authenticated(): boolean {    
    return localStorage.localst !== 'false' ;

  }
  get currentUserObservable(): any {
    console.log(firebase.auth())
    return firebase.auth()
  }

  signupUser(email: string, password: string) {
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .catch(
        error => console.log(error)
      )
  }

  signinUser(email: string, password: string) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(
        response => {
          this.router.navigate(['/dashboard']);
          this._session = response.user.uid
          localStorage.setItem('localst', 'true');
          firebase.auth().currentUser.getIdToken()
            .then(
              (token: string) => this.token = token              
            )
        }
      )
      .catch(
        error => {
          console.log(error.message)
          this.siginerror = error.message
        }
      );
  }
  signinUsers(email: string, password: string) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(
        response => {
          this.router.navigate(['/users']);
          console.log(response.user.uid)
          firebase.auth().currentUser.getIdToken()
            .then(
              (token: string) => this.token = token
            )
        }
      )
      .catch(
        error => console.log(error)
      );
  }
  logout() {
    firebase.auth().signOut();
    this.token = null;
  }
  logouts() {
    firebase.auth().signOut();
    this.token = null;
    localStorage.setItem('localst', 'false');
        this.router.navigate(['/login']);
  }

  getToken() {
    firebase.auth().currentUser.getIdToken()
      .then(
        (token: string) =>
          this.token = token
          
        
      );
    return this.token;
  }

  isAuthenticated() {
    return this.token != null;
  }
}
