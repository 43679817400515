import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],
})

export class TopBarComponent implements OnInit {
  sidebarshow: boolean = true;
  sidebarshowmobile: boolean = true;
  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() messageEventMobile = new EventEmitter<boolean>();
  constructor(private authService: AuthService) {
  // translate.use('en').then(() => {
  //     console.log(translate.data);
  //   });

  }
  sendMessage() {
    
  }     
  ngOnInit() {
    
     
  }
  
  
  
  sidebar(){
    this.sidebarshow = !this.sidebarshow;   
    this.messageEvent.emit(this.sidebarshow)
    console.log("event") 
  }
  sidebarmobile(){
    this.sidebarshowmobile = !this.sidebarshowmobile;   
    this.messageEvent.emit(this.sidebarshowmobile)
    console.log("event") 
  }

 onLogout() {
  this.authService.logouts();
}
}
