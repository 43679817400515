import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  response : any;
  dbChannels : boolean;
  iosvalue : boolean;
  constructor(private authService: AuthService,private http:HttpClient) { }

  ngOnInit() {
    let obt = this.http.get('https://cricket-fans-chat.firebaseio.com/ShowIconsForIos.json');
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbChannels = this.response.show  ;
      console.log(this.dbChannels)
    });
  }
  Resfresh() {
    let obt = this.http.get('https://cricket-fans-chat.firebaseio.com/ShowIconsForIos.json');
    obt.subscribe((response) =>{
      this.response = response ;
      this.dbChannels = this.response.show  ;
      console.log(this.dbChannels)
    });
  }
  show: boolean = false;
  clickEvent(event){
    //Haven't really got far
   this.show = !this.show;     
 } 
 shows: boolean = false;
  clickEvents(event){
    //Haven't really got far
   this.shows = !this.shows;     
 }
 iOs(){
   console.log(this.dbChannels == false)
   if(this.dbChannels == false){
     console.log("TRUE")
     firebase.database().ref('ShowIconsForIos/show').set(Boolean(true)).then((data) => { });
     this.dbChannels = true;
   }else{
    console.log("FALSE")
    firebase.database().ref('ShowIconsForIos/show').set(Boolean(false)).then((data) => {});
    this.dbChannels = false;


   }
  
 }
}
